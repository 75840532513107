import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { SplitColumn } from '../components/V2/SplitColumn/SplitColumn';
import { BackgroundGrey } from '../GlobalStyles';
import { HighlightBlockNoImage } from '../components/V2/HighlightBlocks/HighlightBlockNoImage/HighlightBlockNoImage';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { TextGrid } from '../components/V2/TextGrid/TextGrid';

const CompanyPageJSON = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  name: 'Oqcam',
  url: 'https://oqcam.com/',
  logo: 'https://oqcam.com/assets/images/DarkOqcam.svg',
  sameAs: [
    'https://twitter.com/oqcam',
    'https://www.youtube.com/@Oqcam',
    'https://www.linkedin.com/company/oqcam/',
    'https://oqcam.com/'
  ]
});

const Company = () => {
  return (
    <PageContainer>
      <HelmetContainer currentPage={'company'} title="About us" json={CompanyPageJSON} />
      <Header />

      <LargeHero
        Background={'/assets/images/pages/partners/partner-bg.webp'}
        BackgroundStyle={'cover'}
        Copy={
          '<h1><b>Accelerating Intelligent</b> Manufacturing</h1><p>Oqcam is a software company solving today’s manufacturing challenges by combining state-of-the-art AI, cloud, simulation, and the best in CADCAM technology for <a href="/additive-manufacturing/">additive manufacturing</a>, <a href="/3d-scanning-solutions">3D scanning</a> and <a href="/dental">dental production</a>.</p>'
        }
        Button={{
          copy: 'Contact Us',
          link: '/contact-us/'
        }}
      />

      <SplitColumn
        Copy={
          '<h4>Our vision</h4><p>Over the next few decades we expect to see transformations across our societies and economies in areas such as mobility, constructions, energy, agriculture, urban planning, and healthcare. All these require significant increases in the rate of innovation. At Oqcam, we believe software based on Artificial Intelligence will make innovations in advanced manufacturing broadly accessible and economically viable: accelerating intelligent manufacturing.</p>'
        }
        TextSize={'S'}
        Background={'White'}
        ImageOverlap={false}
        ImageBackground={false}
        Image={{
          src: '/assets/images/pages/company/our-vision.png',
          alt: ''
        }}
        Button={{
          theme: 'stripped',
          copy: 'Read our blog to learn more',
          link: '/posts/how-oqcams-ai-driven-automation-accelerates-manufacturing/'
        }}
      />
      <SplitColumn
        Copy={
          '<h4>Autonomous manufacturing</h4><p>Underpinning our vision to accelerate intelligent manufacturing, our Manufacturing OS enables autonomous manufacturing for companies of all sizes. With this open and highly automated application-specific manufacturing operating system, we are applying artificial intelligence to real manufacturing challenges that is actionable by both humans and machines.</p>'
        }
        TextSize={'S'}
        ImagePosition={'Left'}
        Background={'White'}
        ImageOverlap={false}
        ImageBackground={false}
        Image={{
          src: '/assets/images/pages/company/autonomous-manufacturing.png',
          alt: ''
        }}
        Button={{
          copy: 'Learn More',
          theme: 'stripped',
          link: '/manufacturing-os/'
        }}
      />

      <SplitColumn
        Copy={
          '<h4>Developers of industry-leading manufacturing software</h4><p>Our manufacturing software technology is focused on three verticals: additive manufacturing, 3D scanning, and dental lab production. We are proud to be the developers of Geomagic software for reverse engineering and inspection applications. Our additive manufacturing software helps industrial and healthcare organizations drive innovation and efficiency, delivering AI-powered capabilities for medical image segmentation, additive design, build prep, MES, additive inspection, and simulation. </p>'
        }
        TextSize={'S'}
        Background={'White'}
        ImageOverlap={false}
        ImageBackground={false}
        Image={{
          src: '/assets/images/pages/company/Oqcam-circle.png',
          alt: ''
        }}
      />

      <BackgroundGrey>
        <TextGrid
          Title={'The forefront of technology and industry'}
          Layout={'layout-2'}
          Items={[
            {
              subtitle: '01',
              title: 'Artificial Intelligence and Machine Learning',
              copy: 'Digital manufacturing processes often require large and complex data sets. Our AI and ML team is developing infrastructure and high-fidelity models based on real factory data to fully automate the manufacturing process from design to production to logistics.'
            },
            {
              subtitle: '02',
              title: 'Engineering',
              copy: 'Our team of 200 software engineers is the best in the business. Dedicated to building world-class customer-focused solutions, our engineers have experience in building some of the most powerful tools in the manufacturing industry.'
            },
            {
              subtitle: '03',
              title: 'Customer Experience',
              copy: "The best solutions are those that put their customer first. Across our product portfolio we invest heavily in providing a delightful customer and user experience. Our experienced global support experts constantly strive to keep our customer's needs at our center."
            },
            {
              subtitle: '04',
              title: 'Additive Manufacturing',
              copy: 'Our additive experts are building knowledge into our technology, enabling smart additive workflows requiring minimal user intervention. We’re hyper-focused on delivering vertical design experiences and simulation-driven build prep alongside a flexible, easy to deploy and automated solution for managing <a href="/additive/">additive manufacturing</a>.'
            },
            {
              subtitle: '05',
              title: '3D Scanning',
              copy: 'Geomagic is the name in best-in-class <a href="/3d-scanning-solutions/">3D scanning technology</a> for reverse engineering and inspection. We’ve been responsible for developing Geomagic technology since 2021 and are focused on accelerating innovation for our community of users through quarterly releases, subscription offerings, and tailored OEM solutions.'
            },
            {
              subtitle: '06',
              title: 'Dental Lab Production',
              copy: 'To help <a href="/dental/">dental labs</a> improve efficiency with smart automation and overcome the labor challenges in the dental industry, we\'ve developed an advanced AI-enabled Manufacturing OS. Tailored to dental production applications, this solution  accelerates the automation of dental lab production workflows to increase productivity, save time, and reduce cost.'
            }
          ]}
        />
      </BackgroundGrey>

      <HighlightBlockNoImage
        Theme={'Dark-Primary'}
        Title={'<h3><b>Interested in joining us?</b><br/>Explore our global opportunities</h3>'}
        Button={{
          copy: 'Go To Jobs',
          link: '/careers/'
        }}
      />

      <ArticleBlock Title={'Company News'} Layout={'layout-3'} MostRecentBlogs={true} />

      <Footer lang={'en'} />
    </PageContainer>
  );
};

export default Company;
